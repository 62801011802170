<app-breadcrumb *ngIf="!unique_id" [title]="'Add Admin'" [items]="['Admins']" [active_item]="'Add Admin'"
                [icon]="'fa-user-shield fas'"
                [url]="'/admin'"></app-breadcrumb>

<app-breadcrumb *ngIf="unique_id" [title]="'Update Admin'" [items]="['Admins']" [active_item]="'Update Admin'"
                [icon]="'fa-user-shield fas'"
                [url]="'/admin'"></app-breadcrumb>
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12 col-xl-12 col-lg-12 col-md-12  box-col-12">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="card card-shadow">
                        <div class="card-header shadow-1px-light p-25px">
                            <h5 *ngIf="!unique_id">Add Admin</h5>
                            <h5 *ngIf="unique_id">Update Admin</h5>
                        </div>
                        <div class="card-body shadow-1px-light">
                            <form class="theme-form mega-form" [formGroup]="addAdminForm">
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label class="col-form-label"><i class="fa fa-user-alt"></i> Name</label>
                                            <input class="form-control shadow-1px-light" type="text"
                                                   formControlName="name"
                                                   placeholder="Enter Name"/>
                                            <small *ngIf="submitted && addAdminForm.get('name').hasError('required')"
                                                   class="text-danger">
                                                Name field is required.
                                            </small>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label class="col-form-label"><i class="fa fa-user"></i> Username</label>
                                            <input class="form-control shadow-1px-light" type="text"
                                                   formControlName="username"
                                                   placeholder="Enter Username"/>
                                            <small *ngIf="submitted && addAdminForm.get('username').hasError('required')"
                                                   class="text-danger">
                                                Username field is required.
                                            </small>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label for="email"><i class="fa fa-envelope"></i> Email</label>
                                            <input class="form-control shadow-1px-light" id="email" type="text"
                                                   formControlName="email" placeholder="Enter Email">
                                            <small *ngIf="submitted && addAdminForm.get('email').hasError('required')"
                                                   class="text-danger">
                                                Email field is required.
                                            </small>
                                            <small *ngIf="submitted && addAdminForm.controls['email'].errors && addAdminForm.controls['email'].errors.pattern"
                                                   class="text-danger">Invalid email format</small>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label class="col-form-label"><i class="fa fa-phone"></i> Contact</label>
                                            <input class="form-control shadow-1px-light" id="contact" type="number"
                                                   formControlName="contact" placeholder="Enter Contact">
                                            <small *ngIf="submitted && addAdminForm.get('contact').hasError('required')"
                                                   class="text-danger">
                                                Contact field is required.
                                            </small>
                                            <small *ngIf="submitted && addAdminForm.controls['contact'].errors && addAdminForm.controls['contact'].errors.pattern"
                                                   class="text-danger">
                                                Invalid contact format
                                            </small>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label class="col-form-label"><i class="fa fa-home"></i> Address</label>
                                            <input class="form-control shadow-1px-light" type="text"
                                                   formControlName="address"
                                                   placeholder="Enter Address"/>
                                            <small *ngIf="submitted && addAdminForm.get('address').hasError('required')"
                                                   class="text-danger">
                                                Address Field is required.
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label class="col-form-label"><i class="fa fa-building"></i> States</label>
                                            <select class="form-select" aria-label="Default select example"
                                                    formControlName="state_id">
                                                <option value="">Select State</option>
                                                <option value="{{state.id}}"
                                                        *ngFor="let state of stateData">{{state.name}}</option>
                                            </select>
                                            <small *ngIf="submitted && addAdminForm.get('state_id').hasError('required')"
                                                   class="text-danger">
                                                State field is required.
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label class="col-form-label"><i class="fa fa-map-pin"></i> Pin</label>
                                            <input class="form-control shadow-1px-light" type="number" [(ngModel)]="postalCode" (keyup)="validatePin()"
                                                   formControlName="pin"
                                                   placeholder="Enter Pin"/>
                                            <small *ngIf="submitted && addAdminForm.get('pin').hasError('required')"
                                                   class="text-danger">
                                                Pin field is required.
                                            </small>
                                            <div *ngIf="submitted && addAdminForm.get('pin').hasError('pattern')"
                                                 class="text-danger">
                                                Pin must be a 6-digit number.
                                            </div>
                                            <small *ngIf="postalCodeError"
                                                   class="text-danger">Special character not allowed.</small>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12" *ngIf="!unique_id">
                                        <div class="form-group">
                                            <label class="col-form-label"><i class="fa fa-keyboard-o"></i>
                                                Password</label>
                                            <input class="form-control shadow-1px-light" type="password"
                                                   autocomplete="cc-password" formControlName="password"
                                                   placeholder="Enter Password"/>
                                            <small *ngIf="submitted && addAdminForm.get('password').hasError('required')"
                                                   class="text-danger">
                                                Password field is required.
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="card-footer d-lg-block shadow-1px-light p-25px">
                            <!--                            <button class="btn btn-primary mr-1 btn-pill pull-right" (click)="submit()"-->
                            <!--                                    *ngIf="!unique_id">Submit-->
                            <!--                            </button>-->
                            <!--                            <button class="btn btn-primary mr-1 btn-pill pull-right" (click)="update()"-->
                            <!--                                    *ngIf="unique_id">Update-->
                            <!--                            </button>-->

                            <div class="row">
                                <div class="col text-center">
                                    <button class="btn btn-primary btn-block pull-right btn-raised color-white mr-2"
                                            (click)="submit()"
                                            *ngIf="!unique_id">Submit
                                    </button>
                                    <button class="btn btn-primary btn-block pull-right btn-raised color-white mr-2"
                                            (click)="update()"
                                            *ngIf="unique_id">Update
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
