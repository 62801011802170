import {Injectable, OnDestroy} from "@angular/core";
import {Subject, BehaviorSubject, fromEvent} from "rxjs";
import {takeUntil, debounceTime} from "rxjs/operators";
import {Router} from "@angular/router";

// Menu
export interface Menu {
    headTitle1?: string;
    headTitle2?: string;
    path?: string;
    title?: string;
    icon?: string;
    type?: string;
    badgeType?: string;
    badgeValue?: string;
    active:boolean;
    bookmark?: boolean;
    children?: Menu[];
}

@Injectable({
    providedIn: "root",
})
export class NavService implements OnDestroy {
    private unsubscriber: Subject<any> = new Subject();
    public screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

    // Search Box
    public search: boolean = false;

    // Language
    public language: boolean = false;

    // Mega Menu
    public megaMenu: boolean = false;
    public levelMenu: boolean = false;
    public megaMenuColapse: boolean = window.innerWidth < 1199;

    // Collapse Sidebar
    public collapseSidebar: boolean = window.innerWidth < 991;

    // For Horizontal Layout Mobile
    public horizontal: boolean = window.innerWidth >= 991;

    // Full screen
    public fullScreen: boolean = false;

    constructor(private router: Router) {
        this.setScreenWidth(window.innerWidth);
        fromEvent(window, "resize")
            .pipe(debounceTime(1000), takeUntil(this.unsubscriber))
            .subscribe((evt: any) => {
                this.setScreenWidth(evt.target.innerWidth);
                if (evt.target.innerWidth < 991) {
                    this.collapseSidebar = true;
                    this.megaMenu = false;
                    this.levelMenu = false;
                }
                if (evt.target.innerWidth < 1199) {
                    this.megaMenuColapse = true;
                }
            });
        if (window.innerWidth < 991) {
            // Detect Route change sidebar close
            this.router.events.subscribe((event) => {
                this.collapseSidebar = true;
                this.megaMenu = false;
                this.levelMenu = false;
            });
        }
    }

    ngOnDestroy() {
        // this.unsubscriber.next();
        this.unsubscriber.complete();
    }

    private setScreenWidth(width: number): void {
        this.screenWidth.next(width);
    }

    // @ts-ignore
    // @ts-ignore
    // @ts-ignore
    MENUITEMS: Menu[] = [
        /*{
            headTitle1: "Pages",
        },*/
        {
            path: "/dashboard",
            icon: "fas fa-home",
            title: "Dashboard",
            type: "link",
            //@ts-ignore
            availableTo: ["SUPER_ADMIN","ADMIN","HEAD_COUNSELLOR","COUNSELLOR"],
            bookmark: true
        },
        {
            icon: "fa-sliders-h fas",
            type: "sub",
            badgeType: "light-primary",
            title: "Sessions",
            active: false,
            //@ts-ignore
            availableTo: ["SUPER_ADMIN"],
            children: [
                {
                    path: "/session/add",
                    title: "Add",
                    type: "link",
                    //@ts-ignore
                    availableTo: ["SUPER_ADMIN"],
                },
                {
                    path: "/session",
                    title: "List",
                    type: "link",
                    //@ts-ignore
                    availableTo: ["SUPER_ADMIN"],
                }
            ],
        },
        {
            icon: "fa-user-shield fas",
            type: "sub",
            badgeType: "light-primary",
            title: "Admin",
            active: false,
            //@ts-ignore
            availableTo: ["SUPER_ADMIN"],
            children: [
                {
                    path: "/admin/add",
                    title: "Add",
                    type: "link",
                    //@ts-ignore
                    availableTo: ["SUPER_ADMIN"],
                },
                {
                    path: "/admin",
                    title: "List",
                    type: "link",
                    //@ts-ignore
                    availableTo: ["SUPER_ADMIN"],
                }
            ],
        },
        {
            icon: "fa-user-tie fas",
            type: "sub",
            badgeType: "light-primary",
            title: "Head Counsellors",
            //@ts-ignore
            availableTo: ["SUPER_ADMIN","ADMIN"],
            active: false,
            children: [
                {
                    path: "/head-counsellor/add",
                    title: "Add",
                    type: "link",
                    //@ts-ignore
                    availableTo: ["SUPER_ADMIN","ADMIN"],
                },
                {
                    path: "/head-counsellor",
                    title: "List",
                    type: "link",
                    //@ts-ignore
                    availableTo: ["SUPER_ADMIN","ADMIN"],
                }
            ],
        },
        {
            icon: "fas fa-user-tag",
            type: "sub",
            badgeType: "light-primary",
            title: "Counsellors",
            //@ts-ignore
            availableTo: ["SUPER_ADMIN","ADMIN","HEAD_COUNSELLOR"],
            active: false,
            children: [
                {
                    path: "/counsellor/add",
                    title: "Add",
                    type: "link",
                    //@ts-ignore
                    availableTo: ["SUPER_ADMIN","ADMIN","HEAD_COUNSELLOR"],
                },
                {
                    path: "/counsellor",
                    title: "List",
                    type: "link",
                    //@ts-ignore
                    availableTo: ["SUPER_ADMIN","ADMIN","HEAD_COUNSELLOR"],
                }
            ],
        },
        {
            icon: "fa-code-branch fas",
            type: "sub",
            badgeType: "light-primary",
            title: "Levels",
            //@ts-ignore
            availableTo: ["SUPER_ADMIN"],
            active: false,
            children: [
                {
                    path: "/level/stage",
                    title: "Stages",
                    type: "link",
                    //@ts-ignore
                    availableTo: ["SUPER_ADMIN"],
                },
                {
                    path: "/level/reason",
                    title: "Reasons",
                    type: "link",
                    //@ts-ignore
                    availableTo: ["SUPER_ADMIN"],
                },
                {
                    path: "/level/sr-mapping",
                    title: "SR Mappings",
                    type: "link",
                    //@ts-ignore
                    availableTo: ["SUPER_ADMIN"],
                }
            ],
        },
        {
            icon: "fas fa-bars-progress",
            type: "sub",
            badgeType: "light-primary",
            title: "Modules",
            //@ts-ignore
            availableTo: ["SUPER_ADMIN"],
            active: false,
            children: [
                {
                    path: "/module/program",
                    title: "Programs",
                    type: "link",
                    //@ts-ignore
                    availableTo: ["SUPER_ADMIN"],
                },
                {
                    path: "/module/course",
                    title: "Courses",
                    type: "link",
                    //@ts-ignore
                    availableTo: ["SUPER_ADMIN"],
                },
                {
                    path: "/module/branch",
                    title: "Branches",
                    type: "link",
                    //@ts-ignore
                    availableTo: ["SUPER_ADMIN"],
                },
                {
                    path: "/module/pcb-mapping",
                    title: "PCB Mappings",
                    type: "link",
                    //@ts-ignore
                    availableTo: ["SUPER_ADMIN"],
                }
            ],
        },
        {
            icon: "fas fa-arrows-spin",
            type: "sub",
            badgeType: "light-primary",
            title: "Social Leads",
            //@ts-ignore
            availableTo: ["SUPER_ADMIN","ADMIN","HEAD_COUNSELLOR","COUNSELLOR"],
            active: false,
            children: [
                {
                    path: "/social-lead/manual",
                    title: "Manual",
                    type: "link",
                    //@ts-ignore
                    availableTo: ["SUPER_ADMIN","ADMIN","HEAD_COUNSELLOR","COUNSELLOR"],
                },
                {
                    path: "/social-lead/facebook",
                    title: "Facebook",
                    type: "link",
                    //@ts-ignore
                    availableTo: ["SUPER_ADMIN","ADMIN","HEAD_COUNSELLOR","COUNSELLOR"],
                },
                {
                    path: "/social-lead/google",
                    title: "Google",
                    type: "link",
                    //@ts-ignore
                    availableTo: ["SUPER_ADMIN","ADMIN","HEAD_COUNSELLOR","COUNSELLOR"],
                }
            ],
        },
        {
            icon: "fa-user-graduate fas",
            type: "sub",
            badgeType: "light-primary",
            title: "Students",
            //@ts-ignore
            availableTo: ["SUPER_ADMIN","ADMIN","HEAD_COUNSELLOR","COUNSELLOR"],
            active: false,
            children: [
                // {
                //     path: "/student/add",
                //     title: "Add",
                //     type: "link"
                // },
                {
                    path: "/student",
                    title: "List",
                    type: "link",
                    //@ts-ignore
                    availableTo: ["SUPER_ADMIN","ADMIN","HEAD_COUNSELLOR","COUNSELLOR"],
                }
            ],
        },

        {
            path: "/leads",
            icon: "fa-chart-pie fas",
            title: "Leads",
            type: "link",
            //@ts-ignore
            availableTo: ["COUNSELLOR"],
            bookmark: true
        },
        {
            path: "/report",
            icon: "fa-chart-pie fas",
            title: "Reports",
            type: "link",
            //@ts-ignore
            availableTo: ["SUPER_ADMIN","ADMIN","HEAD_COUNSELLOR","COUNSELLOR"],
            bookmark: true
        }
    ];

    // Array
    items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
}
