import {Routes} from "@angular/router";
import {PrivacyPolicyComponent} from "../../components/privacy-policy/privacy-policy.component";

export const content: Routes = [
    {
        path: "dashboard",
        loadChildren: () => import("../../components/dashboard/dashboard.module").then((m) => m.DashboardModule)
    },
    {
        path: "session",
        loadChildren: () => import("../../components/session/session.module").then((m) => m.SessionModule)
    },
    {
        path: "admin",
        loadChildren: () => import("../../components/admin/admin.module").then((m) => m.AdminModule)
    },
    {
        path: "head-counsellor",
        loadChildren: () => import("../../components/head-counsellor/head-counsellor.module").then((m) => m.HeadCounsellorModule)
    },
    {
        path: "counsellor",
        loadChildren: () => import("../../components/counsellor/counsellor.module").then((m) => m.CounsellorModule)
    },
    {
        path: "level/stage",
        loadChildren: () => import("../../components/stage/stage.module").then((m) => m.StageModule)
    },
    {
        path: "level/reason",
        loadChildren: () => import("../../components/reason/reason.module").then((m) => m.ReasonModule)
    },
    {
        path: "level/sr-mapping",
        loadChildren: () => import("../../components/sr-mapping/sr-mapping.module").then((m) => m.SrMappingModule)
    },
    {
        path: "module/program",
        loadChildren: () => import("../../components/program/program.module").then((m) => m.ProgramModule)
    },
    {
        path: "module/course",
        loadChildren: () => import("../../components/course/course.module").then((m) => m.CourseModule)
    },
    {
        path: "module/branch",
        loadChildren: () => import("../../components/branch/branch.module").then((m) => m.BranchModule)
    },
    {
        path: "module/pcb-mapping",
        loadChildren: () => import("../../components/pcb-mapping/pcb-mapping.module").then((m) => m.PcbMappingModule)
    },
    {
        path: "social-lead/manual",
        loadChildren: () => import("../../components/manual/manual.module").then((m) => m.ManualModule)
    },
    {
        path: "social-lead/facebook",
        loadChildren: () => import("../../components/facebook/facebook.module").then((m) => m.FacebookModule)
    },
    {
        path: "social-lead/google",
        loadChildren: () => import("../../components/google/google.module").then((m) => m.GoogleModule)
    },
    {
        path: "student",
        loadChildren: () => import("../../components/student/student.module").then((m) => m.StudentModule)
    },
    {
        path: "leads",
        loadChildren: () => import("../../components/leads/leads.module").then((m) => m.LeadsModule)
    },
    {
        path: "report",
        loadChildren: () => import("../../components/report/report.module").then((m) => m.ReportModule)
    },
    {
        path: "profile",
        loadChildren: () => import("../../components/profile/profile.module").then((m) => m.ProfileModule)
    }];
