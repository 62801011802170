import { Component } from '@angular/core';
import {StorageService} from "../../shared/services/storage.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent {
  constructor() {
    /*if (!StorageService.getItem('self'))
    {
      this.router.navigateByUrl('privacy-policy')
    }*/
  }
}
