import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {AppUrl} from "../constants/app-url";
import {BaseService} from "./base.service";
import {HttpClient} from "@angular/common/http";
import {AlertService} from "./alert.service";
import {GlobalService} from "./global.service";

@Injectable({
    providedIn: 'root'
})
export class AdminService extends BaseService {

    constructor(public http: HttpClient,
                public alertService: AlertService,
                public globalService: GlobalService) {
        super(http, alertService, globalService);
    }

    create(data): Observable<any> {
        return this.postRequest(AppUrl.ADMINS(), data);
    }

    get(data): Observable<any> {
        return this.getRequest(AppUrl.ADMINS(), data);
    }

    detail(unique_id): Observable<any> {
        return this.getRequest(AppUrl.ADMINS(unique_id));
    }
    count(): Observable<any> {
        return this.getRequest(AppUrl.ADMINS_COUNT());
    }

    update(unique_id, data): Observable<any> {
        return this.putRequest(AppUrl.ADMINS(unique_id), data);
    }

    delete(unique_id): Observable<any> {
        return this.deleteRequest(AppUrl.ADMINS(unique_id));
    }

    updateStatus(unique_id, data): Observable<any> {
        return this.putRequest(AppUrl.ADMIN_STATUS(unique_id), data);
    }
}
