<div class="media profile-media">
    <img class="b-r-10" src="assets/images/dashboard/profile.png" alt="" *ngIf="!userData?.files">
    <img class="rounded-circle" [src]="profileImg" alt="" *ngIf="userData?.files" width="50px" height="50px">
    <div class="media-body"><span>{{userData?.name}}</span>
        <p class="mb-0 font-roboto">
            <span *ngIf="userData && userData.user_type ==='SUPER_ADMIN'">Superadmin</span>
            <span *ngIf="userData && userData.user_type ==='ADMIN'">Admin</span>
            <span *ngIf="userData && userData.user_type ==='HEAD_COUNSELLOR'">Head Counsellor</span>
            <span *ngIf="userData && userData.user_type ==='COUNSELLOR'">Counsellor</span>
            <i class="middle fa fa-angle-down ml-5px"></i>
        </p>
    </div>
</div>
<ul class="profile-dropdown onhover-show-div">
    <li>
        <span (click)="goTo('/profile')">
            <app-feather-icons [icon]="'user'"></app-feather-icons>
            <span>Profile</span>
        </span>
    </li>
    <!--<li>
        <a href="#">
            <app-feather-icons [icon]="'mail'"></app-feather-icons>
        <span>Inbox</span>
        </a>
    </li>-->
    <!--<li>
        <a href="/to-do">
            <app-feather-icons [icon]="'file-text'"></app-feather-icons>
            <span>Taskboard</span>
        </a>
    </li>
    <li>
        <a href="/faq">
            <app-feather-icons [icon]="'settings'"></app-feather-icons>
            <span>Settings</span>
        </a>
    </li>-->
    <li (click)="logout()">
        <app-feather-icons [icon]="'log-in'"></app-feather-icons>
        <span>Log out</span>
    </li>
</ul>
