import {environment} from '../../../environments/environment';
import {Injectable} from '@angular/core';

@Injectable()
export class AppUrl {

    static get ADMIN_URL(): string {
        return environment.appUrl + 'admin/';
    }

    static get API_URL(): string {
        return environment.appUrl + 'api/';
    }

    static get LOGIN(): string {
        return AppUrl.ADMIN_URL + 'login';
    }

    static get SELF(): string {
        return AppUrl.ADMIN_URL + 'self';
    }

    static UPDATE_SELF(): string {
        return AppUrl.ADMIN_URL + 'update-self';
    }

    static UPDATE_PASSWORD(uniqueId?): string {
        if (uniqueId) {
            return AppUrl.ADMIN_URL + 'update-password/' + uniqueId;
        }
    }

    static UPDATE_STATUS(uniqueId?): string {
        if (uniqueId) {
            return AppUrl.ADMIN_URL + 'head-counsellor-status/' + uniqueId;
        }
    }

    static ManualFollowUp(): string {
            return AppUrl.ADMIN_URL + 'manual-follow-up' ;
    }

    static LOGOUT(): string {
        return AppUrl.ADMIN_URL + 'logout';
    }

    static FILE_UPLOAD(): string {
        return AppUrl.ADMIN_URL + 'file-upload';
    }

    static STATES(): string {
        return AppUrl.ADMIN_URL + 'states';
    }
    static CITIES(id?:any): string {
        if (id)
        {
            return AppUrl.ADMIN_URL + 'cities/'+id;
        }
        else{
            return AppUrl.ADMIN_URL + 'cities';
        }
    }

    static USER(id?): string {
        if (id) {
            return AppUrl.ADMIN_URL + 'user/' + id;
        } else {
            return AppUrl.ADMIN_URL + 'user';
        }
    }

    static SELF_PROFILE_PASSWORD(): string {
        return AppUrl.ADMIN_URL + 'update-user-password';
    }

    static SESSIONS(uniqueId?): string {
        if (uniqueId) {
            return AppUrl.ADMIN_URL + 'session/' + uniqueId;
        } else {
            return AppUrl.ADMIN_URL + 'session';
        }
    }

    static SESSIONS_COUNT(): string {
        return AppUrl.ADMIN_URL + 'session-count';
    }

    static SESSION_STATUS(uniqueId?): string {
        if (uniqueId) {
            return AppUrl.ADMIN_URL + 'session-status/' + uniqueId;
        }
    }

    static DEFAULT_SESSION(id?): string {
        if (id) {
            return AppUrl.ADMIN_URL + 'default-session/' + id;
        } else {
            return AppUrl.ADMIN_URL + 'default-session';
        }
    }

    static ACTIVE_SESSION_COUNT(): string {
        return AppUrl.ADMIN_URL + 'active-session-count';
    }

    static HEAD_COUNSELLOR(uniqueId?): string {
        if (uniqueId) {
            return AppUrl.ADMIN_URL + 'head-counsellor/' + uniqueId;
        } else {
            return AppUrl.ADMIN_URL + 'head-counsellor';
        }
    }
    static HEAD_COUNSELLOR_COUNT(uniqueId?): string {
        return AppUrl.ADMIN_URL + 'head-counsellor-count';

    }

    static STAGES(uniqueId?): string {
        if (uniqueId) {
            return AppUrl.ADMIN_URL + 'stage/' + uniqueId;
        } else {
            return AppUrl.ADMIN_URL + 'stage';
        }
    }

    static SR_MAPPING(uniqueId?): string {
        if (uniqueId) {
            return AppUrl.ADMIN_URL + 'sr-mapping/' + uniqueId;
        } else {
            return AppUrl.ADMIN_URL + 'sr-mapping';
        }
    }
    static SR_MAPPING_REASON(uniqueId?): string {
        if (uniqueId) {
            return AppUrl.ADMIN_URL + 'sr-mapping-reasons/' + uniqueId;
        } else {
            return AppUrl.ADMIN_URL + 'sr-mapping-reasons';
        }
    }
    static PCB_MAPPING(uniqueId?): string {
        if (uniqueId) {
            return AppUrl.ADMIN_URL + 'pcb-mapping/' + uniqueId;
        } else {
            return AppUrl.ADMIN_URL + 'pcb-mapping';
        }
    }

    static REASONS(uniqueId?): string {
        if (uniqueId) {
            return AppUrl.ADMIN_URL + 'reason/' + uniqueId;
        } else {
            return AppUrl.ADMIN_URL + 'reason';
        }
    }
    static REPORTS(uniqueId?): string {
        if (uniqueId) {
            return AppUrl.ADMIN_URL + 'reports/' + uniqueId;
        } else {
            return AppUrl.ADMIN_URL + 'reports';
        }
    }
    static SELF_REPORTS(uniqueId?): string {
        if (uniqueId) {
            return AppUrl.ADMIN_URL + 'self-reports/' + uniqueId;
        } else {
            return AppUrl.ADMIN_URL + 'self-reports';
        }
    }
    static ADMIN_REPORTS(uniqueId?): string {
        if (uniqueId) {
            return AppUrl.ADMIN_URL + 'admin-reports/' + uniqueId;
        } else {
            return AppUrl.ADMIN_URL + 'admin-reports';
        }
    }
    static HEAD_COUNSELLOR_REPORTS(uniqueId?): string {
        if (uniqueId) {
            return AppUrl.ADMIN_URL + 'head-counsellor-reports/' + uniqueId;
        } else {
            return AppUrl.ADMIN_URL + 'head-counsellor-reports';
        }
    }
    static COUNSELLOR_REPORTS(uniqueId?): string {
        if (uniqueId) {
            return AppUrl.ADMIN_URL + 'counsellor-reports/' + uniqueId;
        } else {
            return AppUrl.ADMIN_URL + 'counsellor-reports';
        }
    }
    static DAILY_REPORTS(uniqueId?): string {
        if (uniqueId) {
            return AppUrl.ADMIN_URL + 'daily-report/' + uniqueId;
        } else {
            return AppUrl.ADMIN_URL + 'daily-report';
        }
    }
    static MONTHLY_REPORTS(): string {
            return AppUrl.ADMIN_URL + 'month-reports';
    }
    static TOTAL_LEADS_REPORT(uniqueId?): string {
        if (uniqueId) {
            return AppUrl.ADMIN_URL + 'total-leads-count/' + uniqueId;
        } else {
            return AppUrl.ADMIN_URL + 'total-leads-count';
        }
    }
    static SESSIONS_REPORT(uniqueId?): string {
        if (uniqueId) {
            return AppUrl.ADMIN_URL + 'session-reports/' + uniqueId;
        } else {
            return AppUrl.ADMIN_URL + 'session-reports';
        }
    }
    static PROGRAMS(uniqueId?): string {
        if (uniqueId) {
            return AppUrl.ADMIN_URL + 'program/' + uniqueId;
        } else {
            return AppUrl.ADMIN_URL + 'program';
        }
    }

    static PROGRAMS_STATUS(uniqueId?): string {
        if (uniqueId) {
            return AppUrl.ADMIN_URL + 'program-status/' + uniqueId;
        } else {
            return AppUrl.ADMIN_URL + 'program-status';
        }
    }

    static COURSES(uniqueId?): string {
        if (uniqueId) {
            return AppUrl.ADMIN_URL + 'course/' + uniqueId;
        } else {
            return AppUrl.ADMIN_URL + 'course';
        }
    }
    static COURSES_STATUS(uniqueId?): string {
        if (uniqueId) {
            return AppUrl.ADMIN_URL + 'course-status/' + uniqueId;
        } else {
            return AppUrl.ADMIN_URL + 'course-status';
        }
    }
    static FACEBOOK_STATUS(uniqueId?): string {
        if (uniqueId) {
            return AppUrl.ADMIN_URL + 'course-status/' + uniqueId;
        } else {
            return AppUrl.ADMIN_URL + 'course-status';
        }
    }
    static FACEBOOK(uniqueId?): string {
        if (uniqueId) {
            return AppUrl.ADMIN_URL + 'facebook-leads/' + uniqueId;
        } else {
            return AppUrl.ADMIN_URL + 'facebook-leads';
        }
    }
    static FACEBOOK_FRESH_LEADS(uniqueId?): string {
        if (uniqueId) {
            return AppUrl.ADMIN_URL + 'facebook-fresh-leads/' + uniqueId;
        } else {
            return AppUrl.ADMIN_URL + 'facebook-fresh-leads';
        }
    }
    static GOOGLE(uniqueId?): string {
        if (uniqueId) {
            return AppUrl.ADMIN_URL + 'google-leads/' + uniqueId;
        } else {
            return AppUrl.ADMIN_URL + 'google-leads';
        }
    }
    static GOOGLE_FRESH_LEADS(uniqueId?): string {
        if (uniqueId) {
            return AppUrl.ADMIN_URL + 'google-fresh-leads/' + uniqueId;
        } else {
            return AppUrl.ADMIN_URL + 'google-fresh-leads';
        }
    }

    static BRANCHES(uniqueId?): string {
        if (uniqueId) {
            return AppUrl.ADMIN_URL + 'branch/' + uniqueId;
        } else {
            return AppUrl.ADMIN_URL + 'branch';
        }
    }

    static BRANCHES_STATUS(uniqueId?): string {
        if (uniqueId) {
            return AppUrl.ADMIN_URL + 'branch-status/' + uniqueId;
        } else {
            return AppUrl.ADMIN_URL + 'branch-status';
        }
    }

    static HEAD_COUNSELLOR_STATUS(uniqueId?): string {
        if (uniqueId) {
            return AppUrl.ADMIN_URL + 'unique-link-status/' + uniqueId;
        }
    }

    static ASSIGN_ADMIN(uniqueId?): string {
        if (uniqueId) {
            return AppUrl.ADMIN_URL + 'assign-admin/' + uniqueId;
        }
    }

    static ADMINS(unique_id?): string {
        if (unique_id) {
            return AppUrl.ADMIN_URL + 'admin/' + unique_id;
        } else {
            return AppUrl.ADMIN_URL + 'admin';
        }
    }

    static ADMINS_COUNT(): string {
        return AppUrl.ADMIN_URL + 'admin-count';
    }

    static ADMIN_STATUS(unique_id?): string {
        if (unique_id) {
            return AppUrl.ADMIN_URL + 'admin-status/' + unique_id;
        }
    }

    static ASSIGN_HEAD_COUNSELLOR(uniqueId?): string {
        if (uniqueId) {
            return AppUrl.ADMIN_URL + 'assign-head-counsellor/' + uniqueId;
        } else {
            return AppUrl.ADMIN_URL + 'assign-head-counsellor';
        }
    }

    static COUNSELLORS(id?): string {
        if (id) {
            return AppUrl.ADMIN_URL + 'counsellor/' + id;
        } else {
            return AppUrl.ADMIN_URL + 'counsellor';
        }
    }

    static COUNSELLORS_COUNT(): string {
        return AppUrl.ADMIN_URL + 'counsellor-count';
    }

    static COUNSELLOR_STATUS(id?): string {
        if (id) {
            return AppUrl.ADMIN_URL + 'counsellor-status/' + id;
        }
    }

    static LEADS(id?): string {
        if (id) {
            return AppUrl.ADMIN_URL + 'lead/' + id;
        } else {
            return AppUrl.ADMIN_URL + 'lead';
        }
    }
    static ALL_LEADS(id?): string {
        if (id) {
            return AppUrl.ADMIN_URL + 'all-leads/' + id;
        } else {
            return AppUrl.ADMIN_URL + 'all-leads';
        }
    }

    static STUDENT_DETAIL(id?): string {
        if (id) {
            return AppUrl.ADMIN_URL + 'student_details/' + id;
        } else {
            return AppUrl.ADMIN_URL + 'student_details';
        }
    }

    static LEAD_COUNT(): string {
        return AppUrl.ADMIN_URL + 'lead-count';
    }

    static LEAD_STATUS(id?): string {
        if (id) {
            console.log('id',id)
            return AppUrl.ADMIN_URL + 'lead-assign-status';
        }
    }
    static COUNSELLOR_REPORT()
    {
        return AppUrl.ADMIN_URL + 'counsellors-report/';
    }
    static HEAD_COUNSELLOR_REPORT()
    {
        return AppUrl.ADMIN_URL + 'head-counsellor-report/';
    }
    static ASSIGN_COUNSELLOR(id?): string {
        if (id) {
            return AppUrl.ADMIN_URL + 'lead-assign-counsellor/'+id;
        }
    }
}
