import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {AdminService} from "../../../shared/services/admin.service";
import {StateService} from "../../../shared/services/state.service";
import {StorageService} from "../../../shared/services/storage.service";

@Component({
    selector: 'app-add-admin',
    templateUrl: './add-admin.component.html',
    styleUrls: ['./add-admin.component.scss']
})
export class AddAdminComponent {
    addAdminForm: FormGroup;
    UpdateAdminForm: FormGroup;
    submitted: any = false;
    unique_id: any;
    stateData = [];
    postalCodeError = false;
    postalCode: number;
    user: any;

    constructor(private fb: FormBuilder,
                private activatedRoute: ActivatedRoute,
                private adminService: AdminService,
                private stateService: StateService,
                private router: Router) {
        this.user = StorageService.getItem('self');
        if (this.user.user_type === 'ADMIN' || this.user.user_type === 'HEAD_COUNSELLOR' || this.user.user_type === 'COUNSELLOR') {
            this.router.navigate(['/dashboard']);
        }
        if (this.router.getCurrentNavigation() != null && this.router.getCurrentNavigation().extras != null && this.router.getCurrentNavigation().extras.state != null) {
            this.unique_id = this.router.getCurrentNavigation().extras.state;
        }
    }

    ngOnInit(): void {
        this.refresh();
    }

    refresh() {
        this.getAllStates();
        this.addAdminForm = this.fb.group({
            name: ['', Validators.required],
            username: ['', Validators.required],
            email: ['', Validators.compose([Validators.required, Validators.pattern('^(([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$')])],
            contact: ['', Validators.compose([Validators.required, Validators.pattern('^[5-9]{1}[0-9]{9}$')])],
            address: [''],
            state_id: [''],
            pin: ['', Validators.pattern(/^\d{6}$/)],
            password: ['', !this.unique_id ? Validators.required : '']
        });
        if (this.unique_id) {
            this.detail();
        }
    }

    getAllStates() {
        this.stateService.get().subscribe(data => {
            this.stateData = data['data'];
        });
    }

    detail() {
        this.adminService.detail(this.unique_id).subscribe(data => {
            this.patchForm(data['data'][0]);
        });
    }

    patchForm(data) {
        this.addAdminForm.patchValue({
            name: (data && data.users?.name) ? data.users?.name : '',
            username: (data && data.users?.username) ? data.users?.username : '',
            email: (data && data.users?.email) ? data.users?.email : '',
            contact: (data && data.users?.contact) ? data.users?.contact : '',
            address: (data && data.users?.address) ? data.users?.address : '',
            state_id: (data && data.users?.state_id) ? data.users?.state_id : '',
            pin: (data && data.users?.pin) ? data.users?.pin : '',
        });
    }

    submit() {

        this.submitted = true;
        if (!this.addAdminForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'name': (this.addAdminForm && this.addAdminForm.getRawValue() && this.addAdminForm.getRawValue().name) ? this.addAdminForm.getRawValue().name : '',
                'username': (this.addAdminForm && this.addAdminForm.getRawValue() && this.addAdminForm.getRawValue().username) ? this.addAdminForm.getRawValue().username : '',
                'email': (this.addAdminForm && this.addAdminForm.getRawValue() && this.addAdminForm.getRawValue().email) ? this.addAdminForm.getRawValue().email : '',
                'contact': (this.addAdminForm && this.addAdminForm.getRawValue() && this.addAdminForm.getRawValue().contact) ? this.addAdminForm.getRawValue().contact : '',
                'address': (this.addAdminForm && this.addAdminForm.getRawValue() && this.addAdminForm.getRawValue().address) ? this.addAdminForm.getRawValue().address : '',
                'state_id': (this.addAdminForm && this.addAdminForm.getRawValue() && this.addAdminForm.getRawValue().state_id) ? this.addAdminForm.getRawValue().state_id : '',
                'pin': (this.addAdminForm && this.addAdminForm.getRawValue() && this.addAdminForm.getRawValue().pin) ? this.addAdminForm.getRawValue().pin : '',
                'password': (this.addAdminForm && this.addAdminForm.getRawValue() && this.addAdminForm.getRawValue().password) ? this.addAdminForm.getRawValue().password : '',
            };
            this.adminService.create(params).subscribe(data => {
                this.submitted = false;
                this.addAdminForm.patchValue({
                    'name': '',
                    'username': '',
                    'email': '',
                    'contact': '',
                    'address': '',
                    'state_id': '',
                    'pin': '',
                    'password': '',
                });
                // this.router.navigateByUrl('/admin');
            }, error => {
                this.submitted = false;
            });
        }
    }

    update() {
        this.submitted = true;
        if (!this.addAdminForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'name': (this.addAdminForm && this.addAdminForm.getRawValue() && this.addAdminForm.getRawValue().name) ? this.addAdminForm.getRawValue().name : '',
                'username': (this.addAdminForm && this.addAdminForm.getRawValue() && this.addAdminForm.getRawValue().username) ? this.addAdminForm.getRawValue().username : '',
                'email': (this.addAdminForm && this.addAdminForm.getRawValue() && this.addAdminForm.getRawValue().email) ? this.addAdminForm.getRawValue().email : '',
                'contact': (this.addAdminForm && this.addAdminForm.getRawValue() && this.addAdminForm.getRawValue().contact) ? this.addAdminForm.getRawValue().contact : '',
                'address': (this.addAdminForm && this.addAdminForm.getRawValue() && this.addAdminForm.getRawValue().address) ? this.addAdminForm.getRawValue().address : '',
                'state_id': (this.addAdminForm && this.addAdminForm.getRawValue() && this.addAdminForm.getRawValue().state_id) ? this.addAdminForm.getRawValue().state_id : '',
                'pin': (this.addAdminForm && this.addAdminForm.getRawValue() && this.addAdminForm.getRawValue().pin) ? this.addAdminForm.getRawValue().pin : '',
            };
            this.adminService.update(this.unique_id, params).subscribe(data => {
                this.submitted = false;
                this.router.navigateByUrl('/admin');
            }, error => {
                this.submitted = false;
            });
        }
    }

    // update() {
    //     this.submitted = true;
    //     if (!this.UpdateAdminForm.valid) {
    //         return;
    //     }
    //     if (this.submitted) {
    //         const params = {
    //             'name': (this.UpdateAdminForm && this.UpdateAdminForm.getRawValue() && this.UpdateAdminForm.getRawValue().name) ? this.UpdateAdminForm.getRawValue().name : '',
    //             'username': (this.UpdateAdminForm && this.UpdateAdminForm.getRawValue() && this.UpdateAdminForm.getRawValue().username) ? this.UpdateAdminForm.getRawValue().username : '',
    //             'email': (this.UpdateAdminForm && this.UpdateAdminForm.getRawValue() && this.UpdateAdminForm.getRawValue().email) ? this.UpdateAdminForm.getRawValue().email : '',
    //             'contact': (this.UpdateAdminForm && this.UpdateAdminForm.getRawValue() && this.UpdateAdminForm.getRawValue().contact) ? this.UpdateAdminForm.getRawValue().contact : '',
    //             'address': (this.UpdateAdminForm && this.UpdateAdminForm.getRawValue() && this.UpdateAdminForm.getRawValue().address) ? this.UpdateAdminForm.getRawValue().address : '',
    //             'state_id': (this.UpdateAdminForm && this.UpdateAdminForm.getRawValue() && this.UpdateAdminForm.getRawValue().state_id) ? this.UpdateAdminForm.getRawValue().state_id : '',
    //             'pin': (this.UpdateAdminForm && this.UpdateAdminForm.getRawValue() && this.UpdateAdminForm.getRawValue().pin) ? this.UpdateAdminForm.getRawValue().pin : '',
    //         };
    //         this.adminService.update(this.unique_id, params).subscribe(data => {
    //             this.submitted = false;
    //             this.router.navigateByUrl('/admin');
    //         }, error => {
    //             this.submitted = false;
    //         });
    //     }
    // }

    validatePin() {
        if (typeof (this.postalCode) === "number") {
            this.postalCodeError = false;
        } else {
            this.postalCodeError = true;
            if (this.submitted) {
                this.submitted = false;
            }
        }
    }
}
