import {Component, PLATFORM_ID, Inject, OnInit, OnDestroy} from '@angular/core';
import {LoadingBarService} from '@ngx-loading-bar/core';
import {Subject} from 'rxjs';
import {StorageService} from "./shared/services/storage.service";
import {GlobalService} from "./shared/services/global.service";
import {AuthService} from "./shared/services/auth.service";
import {Router} from "@angular/router";
import {UserService} from "./shared/services/user.service";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit, OnDestroy {
    user: any;
    userData: any;
    private _unsubscribeAll: Subject<any>;

    constructor(@Inject(PLATFORM_ID) private platformId: Object,
                private loader: LoadingBarService,
                private authService: AuthService,
                private router: Router,
                private userService: UserService,
                private globalService: GlobalService) {
        this.user = StorageService.getItem('self');
        this._unsubscribeAll = new Subject();
        this.checkLogin();

        this.globalService.reloadSelf.subscribe((d) => {
            if (d) {
                this.reloadSelf();
                this.globalService.reloadSelf.next(false);
            }
        });
    }

    ngOnInit(): void {
        this.getUserDetail();
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(this.user);
        this._unsubscribeAll.complete();
    }

    getUserDetail() {
        if (this.user && this.user.user_id) {
            this.userService.detail(this.user.user_id).subscribe(data => {
                if (data) {
                    this.userData = data;
                }
            });
        }
    }

    checkLogin() {
        if (StorageService.getItem('accessToken') && StorageService.getItem('self')) {
            this.globalService.setAccessToken(StorageService.getItem('accessToken'));
            this.globalService.setSelf(StorageService.getItem('self'));
            this.reloadSelf();
        } else {
            const url = window.location.href;
            const substring = url.substring(url.lastIndexOf('/') + 1);
            if(substring != 'privacy-policy')
            {
                this.router.navigateByUrl('auth/login');
            }
            // this.router.navigateByUrl('auth/login');
        }
    }

    reloadSelf() {
        this.authService.self({all: true}).subscribe((data) => {
            this.globalService.setSelf({
                'name': data.name,
                'user_id': data.id,
                'username': data.username,
                'email': data.email,
                'contact': data.contact,
                'user_type': data.user_type
            });
            return;
        }, e => {
            StorageService.clearAll();
            const url = window.location.href;
            const substring = url.substring(url.lastIndexOf('/') + 1);
            if(substring != 'privacy-policy')
            {
                this.router.navigateByUrl('auth/login');
            }
            // this.router.navigateByUrl('auth/login');
        });
    }
}
